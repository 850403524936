import React, { Fragment, lazy } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { injectScreendoorScript } from "../../util/router-utils";

const Service = lazy(() => import("./pages/service"));
const ScreendoorForm = lazy(() => import("./pages/screendoor-form"));
const CMSPage = lazy(() => import("./pages/cms"));

const SERVICE = "/service/:slug/*";
const SCREENDOOR_FORM = "/form/:projectId";
const CMS = "/:pageSlug";
const CMS_PREVIEW = "/:pageSlug/:id";

const RouteHelmet = ({
  metadata,
  useContactName,
  title,
  screendoorForm,
  projectIds
}) => {
  const { projectId } = useParams();
  return (
    <Helmet
      onChangeClientState={
        screendoorForm
          ? (_, addedTags) =>
              injectScreendoorScript(projectIds[projectId], addedTags)
          : () => {}
      }
    >
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="86694b3170893e066ff38ffe535a31b3"
          src="//code.jquery.com/jquery-2.2.3.min.js"
        ></script>
      )}
      {screendoorForm && (
        <link
          href="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.css"
          rel="stylesheet"
        />
      )}
      {screendoorForm && (
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        />
      )}
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="86694b3170893e066ff38ffe535a31b3"
          src="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.js"
        ></script>
      )}
      <title>
        {useContactName ? `${metadata?.contactName} ${title}` : title}
      </title>
      <link rel="shortcut icon" href={metadata?.favicon ?? ""} />
    </Helmet>
  );
};

const ProfilePageContainer = ({ onLogout, isLoggedIn, children }) => (
  <Fragment>
    <Header key="main-header" isLoggedIn={isLoggedIn} onLogout={onLogout} />
    {children}
    <Footer key="main-footer" showLogout={isLoggedIn} onLogout={onLogout} />
  </Fragment>
);

export const routes = {
  [SERVICE]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        title={props.workflowMetadata?.workflowPageName ?? ""}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
      >
        <Service {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [SCREENDOOR_FORM]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        projectIds={props.clientMetadata?.data?.screendoorProjectIDs}
        title={
          props.clientMetadata?.data?.clientDisplayName ?? "Screendoor Form"
        }
        screendoorForm
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
      >
        <ScreendoorForm {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [CMS]: props => (
    <Fragment>
      <CMSPage {...props} />
    </Fragment>
  ),
  [CMS_PREVIEW]: props => (
    <Fragment>
      <CMSPage {...props} />
    </Fragment>
  )
};
